import {Dispatch} from "redux";

import {getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, Scenario} from "@pg-mono/rp-api-routes";

import {IPropertyDetail} from "../../property/types/IPropertyDetail";

const FETCH_MY_OFFERS_PROPERTIES_LIST = "my_offers/FETCH_MY_OFFERS_PROPERTIES_LIST";
export const fetchMyOffersPropertiesListTypes = createRequestActionTypes(FETCH_MY_OFFERS_PROPERTIES_LIST);

export const fetchMyOffersPropertiesList = (ids: number[]) => async (dispatch: Dispatch) => {
    dispatch({type: fetchMyOffersPropertiesListTypes.start});

    if (!ids.length) {
        return dispatch({type: fetchMyOffersPropertiesListTypes.success, result: []});
    }

    Promise.all(
        ids.map((id) => {
            const url = apiV2Link.property.detail(Scenario.PROPERTY_DETAIL, {propertyId: id});

            return getRequest({}, url);
        })
    ).then((response: IPropertyDetail[]) => {
        dispatch({type: fetchMyOffersPropertiesListTypes.success, result: response});
    });
};
